//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import {fetchReportSummarize} from "@/api/report";
import func from "@/utils/decoded";

export default {
    name: 'TransferCarReport',
	data() {
		return {
			searchParams: {
				startDate: ''
			},
			privilege: {read: false, write: false, report: false}
		}
	},
	created() {
		document.title = this.$route.name + " : ONE Tracemoney"
		const check = func.checkMenu(this.$route.path)
		if (check && check.length === 0) {
			this.$router.push({path: '/404'})
		}
	},
	async mounted() {
		const check = func.checkMenu(this.$route.path)
		if (check && check.length > 0) {
			this.privilege = check[0]
		}
	},
	methods: {
		handleTransferCarExport() {
			const loading = this.$loading({
				lock: true,
				text: 'Please wait',
				spinner: 'el-icon-loading',
				background: 'rgba(0, 0, 0, 0.7)'
			})

      console.log(this.searchParams)
      loading.close()

			fetchReportSummarize(this.searchParams).then(response => {
				const fileURL = window.URL.createObjectURL(new Blob([response]))
				const fileName = 'ReportTransferCar_' + func.generateReportDate() + '.xlsx'
				const link = document.createElement('a')

				link.href = fileURL
				link.setAttribute('download', fileName)
				document.body.appendChild(link)
				loading.close()
				if (response.size > 0 && response.type !== 'application/json') {
					link.click()
				} else {
					this.$notify({
						title: 'Error',
						message: 'ไม่สามารถสร้างรายงานการโอนเงินสินเชื่อได้',
						type: 'error',
						duration: 5000
					})
				}
				link.remove()

				// Just to simulate the time of the request
				setTimeout(() => {
					loading.close()
				}, 1.5 * 1000)
			})
		},
	}
}
