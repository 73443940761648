import request from '@/utils/request'
const baseURL = process.env.VUE_APP_BASE_API

export function fetchReportStep1(query) {
  return request({
    url: baseURL + '/report/xlsx/checkdocument',
    method: 'get',
    params: query,
    responseType: 'blob'
  })
}

export function fetchReportStep2(query) {
  return request({
    url: baseURL + '/report/xlsx/transportdoc',
    method: 'get',
    params: query,
    responseType: 'blob'
  })
}

export function fetchReportStep3(query) {
  return request({
    url: baseURL + '/report/xlsx/receivebook',
    method: 'get',
    params: query,
    responseType: 'blob'
  })
}

export function fetchReportStep4(params) {
  return request({
    url: baseURL + '/report/xlsx/cartransfer',
    method: 'get',
    params: params,
    responseType: 'blob'
  })
}

export function fetchReportStep5(query) {
  return request({
    url: baseURL + '/report/xlsx/moneytransfer',
    method: 'get',
    params: query,
    responseType: 'blob'
  })
}

export function fetchReportSummarize(query) {
  return request({
    url: baseURL + '/report/xlsx/summarize',
    method: 'get',
    params: query,
    responseType: 'blob'
  })
}

export function fetchCommissionHr(query) {
  return request({
    url: baseURL + '/report/xlsx/commissionhr',
    method: 'get',
    params: query,
    responseType: 'blob'
  })
}

export function fetchCommissionCo(query) {
  return request({
    url: baseURL + '/report/xlsx/commissionco',
    method: 'get',
    params: query,
    responseType: 'blob'
  })
}

export function fetchCommissionReport(query) {
  return request({
    url: baseURL + '/report/pdf/commission',
    method: 'get',
    params: query,
    responseType: 'blob'
  })
}

export function fetchTransferCostReport(query) {
  return request({
    url: baseURL + '/report/pdf/cost',
    method: 'get',
    params: query,
    responseType: 'blob'
  })
}

export function fetchTransferCostExcelReport(query) {
  return request({
    url: baseURL + '/report/xlsx/cost',
    method: 'get',
    params: query,
    responseType: 'blob'
  })
}

export function fetchTransferReport(query) {
  return request({
    url: baseURL + '/report/xlsx/summarize',
    method: 'get',
    params: query,
    responseType: 'blob'
  })
}

export function fetchCommissionHrReport(query) {
  return request({
    url: baseURL + '/report/xlsx/commission/hr',
    method: 'get',
    params: query,
    responseType: 'blob'
  })
}

export function fetchCommissionCoReport(query) {
  return request({
    url: baseURL + '/report/xlsx/commission/co',
    method: 'get',
    params: query,
    responseType: 'blob'
  })
}
